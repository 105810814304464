import React, { useState } from 'react';
import {
  Box,
  TextField,
  Button,
  Typography,
  Snackbar,
  Alert,
  Grid,
  CircularProgress,
  RadioGroup,
  FormControlLabel,
  Radio,
  FormControl,
  FormLabel,
} from '@mui/material';
import { styled } from '@mui/system';
import axios from 'axios';
import AnimatedBackground from './Animation';
import topImage from '../../assets/ESlogo.png';

// Styled Components (unchanged)
const BackgroundContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  minHeight: '100vh',
  padding: theme.spacing(2),
  backgroundSize: 'cover',
  backgroundPosition: 'center',
}));

const FormContainer = styled(Box)(({ theme }) => ({
  width: '60%',
  maxWidth: 600,
  margin: '0 auto',
  padding: theme.spacing(5),
  border: '1px solid #ccc',
  borderRadius: 1,
  backgroundColor: '#fff',
  boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(2),
    width: '90%',
  },
}));

const SubmitButton = styled(Button)(({ theme }) => ({
  width: '100%',
  padding: theme.spacing(1),
  backgroundColor: '#007bff',
  color: '#fff',
  fontSize: 16,
  '&:hover': {
    backgroundColor: '#0056b3',
  },
}));

const TopImage = styled('img')({
  display: 'block',
  width: '100%',
  maxHeight: 150,
  objectFit: 'cover',
  marginBottom: 20,
  borderRadius: 8,
});

const StudentFeedbackForm = () => {
  const [formData, setFormData] = useState({
    studentid: '1',
    name: '',
    DoManagershandledisagreementsproffessinally: '',
    HowFrequentlyDoYouReceiveRecognitionFromYourManagers: '',
    DoYouReqularlyReceiveConstructivePerformanceFeedbackFromYourManagersofHR: '',
    AnyMentalPhysicalAbuseYouFacedWithinOurOrganisation: '',
    DoYouneedanyChangeinHRManagersSourceyesorno: '',
  });

  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      await axios.post('https://qcyokzjqdb.execute-api.ap-south-1.amazonaws.com/prod/api/student/AddFeedback', formData);
      setSuccess(true);
      setFormData({
        studentid: (parseInt(formData.studentid) + 1).toString(),
        name: '',
        DoManagershandledisagreementsproffessinally: '',
        HowFrequentlyDoYouReceiveRecognitionFromYourManagers: '',
        DoYouReqularlyReceiveConstructivePerformanceFeedbackFromYourManagersofHR: '',
        AnyMentalPhysicalAbuseYouFacedWithinOurOrganisation: '',
        DoYouneedanyChangeinHRManagersSourceyesorno: '',
      });
    } catch (err) {
      setError(err.response?.data?.message || 'An error occurred');
    } finally {
      setLoading(false);
    }
  };

  const handleClose = () => {
    setSuccess(false);
    setError(null);
  };

  return (
    <BackgroundContainer>
      <AnimatedBackground />
      <FormContainer>
        <TopImage src={topImage} alt="Top Image" />
        <Typography variant="h6" align="center" gutterBottom>
          Employee Feedback Form
        </Typography>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            {/* Name Field */}
            <Grid item xs={12}>
              <Typography variant="body1" gutterBottom>
                Name
              </Typography>
              <TextField
                name="name"
                value={formData.name}
                onChange={handleChange}
                fullWidth
                required
              />
            </Grid>

            {/* Do Managers Handle Disagreements Professionally? */}
            <Grid item xs={12}>
              <Typography variant="body1" gutterBottom>
                Do Managers Handle Disagreements Professionally?
              </Typography>
              <TextField
                name="DoManagershandledisagreementsproffessinally"
                value={formData.DoManagershandledisagreementsproffessinally}
                onChange={handleChange}
                fullWidth
                required
              />
            </Grid>

            {/* How Frequently Do You Receive Recognition from Your Managers? */}
            <Grid item xs={12}>
              <Typography variant="body1" gutterBottom>
                How Frequently Do You Receive Recognition from Your Managers?
              </Typography>
              <TextField
                name="HowFrequentlyDoYouReceiveRecognitionFromYourManagers"
                value={formData.HowFrequentlyDoYouReceiveRecognitionFromYourManagers}
                onChange={handleChange}
                fullWidth
                required
              />
            </Grid>

            {/* Do You Regularly Receive Constructive Performance Feedback from Your Managers/HR? */}
            <Grid item xs={12}>
              <Typography variant="body1" gutterBottom>
                Do You Regularly Receive Constructive Performance Feedback from Your Managers/HR?
              </Typography>
              <TextField
                name="DoYouReqularlyReceiveConstructivePerformanceFeedbackFromYourManagersofHR"
                value={formData.DoYouReqularlyReceiveConstructivePerformanceFeedbackFromYourManagersofHR}
                onChange={handleChange}
                fullWidth
                required
              />
            </Grid>

            {/* Any Mental or Physical Abuse You Faced Within Our Organization? */}
            <Grid item xs={12}>
              <Typography variant="body1" gutterBottom>
                Any Mental or Physical Abuse You Faced Within Our Organization?
              </Typography>
              <TextField
                name="AnyMentalPhysicalAbuseYouFacedWithinOurOrganisation"
                value={formData.AnyMentalPhysicalAbuseYouFacedWithinOurOrganisation}
                onChange={handleChange}
                fullWidth
                required
              />
            </Grid>

            {/* Do You Need Any Change in HR/Managers? (Yes/No) */}
            <Grid item xs={12}>
              <FormControl component="fieldset">
                <FormLabel component="legend">
                  Do You Need Any Change in HR/Managers? (Yes/No)
                </FormLabel>
                <RadioGroup
                  name="DoYouneedanyChangeinHRManagersSourceyesorno"
                  value={formData.DoYouneedanyChangeinHRManagersSourceyesorno}
                  onChange={handleChange}
                  row
                >
                  <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
                  <FormControlLabel value="No" control={<Radio />} label="No" />
                </RadioGroup>
              </FormControl>
            </Grid>

            {/* Submit Button */}
            <Grid item xs={12}>
              <SubmitButton type="submit" disabled={loading}>
                {loading ? <CircularProgress size={24} color="inherit" /> : 'Submit Feedback'}
              </SubmitButton>
            </Grid>
          </Grid>
        </form>
        <Snackbar open={success} autoHideDuration={6000} onClose={handleClose}>
          <Alert onClose={handleClose} severity="success">
            Feedback submitted successfully!
          </Alert>
        </Snackbar>
        <Snackbar open={!!error} autoHideDuration={6000} onClose={handleClose}>
          <Alert onClose={handleClose} severity="error">
            {error}
          </Alert>
        </Snackbar>
        <Typography align="center" variant="body2" marginTop={3}>
          Powered by EthicSecur SofTec
        </Typography>
      </FormContainer>
    </BackgroundContainer>
  );
};

export default StudentFeedbackForm;