import React,{useState} from "react";
import { Helmet } from "react-helmet";
import { BrowserRouter as Router, Route, Routes, useParams } from 'react-router-dom';
import { Typography } from '@mui/material';
import ProtectedRoute from './ProtectedRoute';

// Screens
import Landing from "./screens/Landing.jsx";
import ChatBots from './components/pages/ChatBot.jsx';
import ScrollButton from './components/Sections/ScrollButton.jsx'

// pages
import Careers from "./components/pages/careers/Careers.jsx";
import Pricing from "./components/Sections/Pricing.jsx";
import Terms from "./components/pages/Terms.jsx";
import Privacy from "./components/pages/Privacy.jsx";
import Refund from "./components/pages/Refund.jsx";
import BlogList from './components/Bloglist.js';
import InternList from './components/Interns.js';
import Contact from "./components/Sections/Contact.jsx";
import About from './components/Sections/About.jsx'
import Certificate from "./components/Sections/Certificate.jsx";

// services pages
import NetworkSecurity from './components/pages/services/NetworkSecurity.jsx'; 
import MarketingServices from './components/pages/services/MarketingServices.jsx';
import WebDevelopment from './components/pages/services/WebDevelopment.jsx';
import ProductionSupport from './components/pages/services/ProductionSupport.jsx';
import DataEncryptionServices from './components/pages/services/DataEncryptionServices.jsx';
import CyberThreatMonitoring from './components/pages/services/CyberThreatMonitoring.jsx';

// blogs
import Blog from "./components/Sections/Blog.jsx";
import Cyber from './components/pages/blogs/Cybersecuritytrends.jsx'
import DataPrivacy from "./components/pages/blogs/DataPrivacy.jsx";
import Futuretrends from "./components/pages/blogs/FutureTrends.jsx";
import Roleofencrypt from "./components/pages/blogs/RoleofEncrypt.jsx";
import Toptrends from "./components/pages/blogs/TopTrends.jsx";
import PhishingScams from "./components/pages/blogs/PhishingScams.jsx";
import Databreach from "./components/pages/blogs/Databreach.jsx";
import Cyberintern from './components/pages/blogs/Cyberintern.jsx'

//Intern
import  Interncertifi from "./components/Sections/Interncertifi.jsx"
import Fullstackintern from "./components/pages/Interns/Fullstack.jsx";




// login page for invoice
import LoginPage from './components/pages/Login/LoginPage.jsx';
import InvoiceDetail from "./components/pages/Login/InvoiceDetail.jsx";
import InvoiceTablePage from "./components/pages/Login/InvoiceTable.jsx";
import CreateInvoice from "./components/pages/Login/CreateInvoice.jsx";
import Addinginternstudentform from "./components/pages/Login/addinginternstudentform.jsx";

// Student Payment Form
import Studentform from "./components/studentPaymentForm/Studentform.jsx";
import UIUX from "./components/studentPaymentForm/UIUX.jsx";
import Fullstack from "./components/studentPaymentForm/Fullstack.jsx";
import Cybersecurity from "./components/studentPaymentForm/Cybersecurity.jsx";
//event capturethe flag
import Capturetheflag from "./components/studentPaymentForm/Capturetheflag.jsx";
import Ctf from "./components/studentPaymentForm/getctf.jsx";
//Hive Student Form
import Hivestudentform from "./components/studentPaymentForm/Hivestudentform.jsx";
import Hivestudent from "./components/studentPaymentForm/gethivestudent.jsx";
import Whatsapp from "./components/studentPaymentForm/whatsapp.jsx";

//Certification
import CertificatePage from "./components/pages/Certification/certificateform.jsx";


//intern
import Cybersecurityintern from "./components/pages/Interns/cybersecurity.jsx"

//getregistered
import GetRegistered from "./components/studentPaymentForm/gethivestudent.jsx";
import GetUIUX from "./components/studentPaymentForm/getuiuxpaymet.jsx";

//Securityanalyintern
import Securityanalyintern from "./components/studentPaymentForm/Securityanalysintern.jsx";

//Feedback
import Feedback from "./components/studentPaymentForm/Feedback.jsx";
import GetFeedback from "./components/studentPaymentForm/GetFeedBack.jsx";

//Hive Student Form
import Hivestudentdetails from "./components/pages/HiveStudentt/Hivestudentde.jsx";

//Feedback
import Employeeform from "./components/pages/FeedBackform/Employeeform.jsx";
import Getperfomance from "./components/pages/FeedBackform/Getperformance.jsx";
const InvoiceDetailWrapper = () => {
  const { id } = useParams();

  // Check if id exists
  if (!id) {
    return <Typography variant="h6">Invoice ID is required.</Typography>; // or any fallback UI
  }

  return <InvoiceDetail invoiceId={id} />;
};


const App = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false); // Authentication state
  return (
    <>
      <Helmet>
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin="true" />
        <link href="https://fonts.googleapis.com/css2?family=Khula:wght@400;600;800&display=swap" rel="stylesheet" />
      </Helmet>
    
        <Routes>
          
{/* pages */}
{/* login page for invoice */}
<Route path="/login" element={<LoginPage setIsAuthenticated={setIsAuthenticated} />} />
<Route path="/addinginternstudentform" element={<Addinginternstudentform />} />
<Route path="/create-invoice" element={<CreateInvoice />} />
<Route path="/invoices-table" element={<ProtectedRoute element={<InvoiceTablePage />} isAuthenticated={isAuthenticated} />} />
<Route path="/invoice/:id" element={<InvoiceDetailWrapper />} /> {/* Use the wrapper here */}


          <Route path="/" element={<Landing />} />
          <Route path="/careers" element={<Careers />} />
          <Route path="/pricing" element={<Pricing />} />
          <Route path="/terms" element={<Terms />} />
          <Route path="/privacy" element={<Privacy />} />
          <Route path="/refund" element={<Refund />} />
          <Route path="/contact" element={<Contact/>} />
          <Route path="/about" element={<About/>} />
          

{/* Services */}
          <Route path="/network-security" element={<NetworkSecurity/>} /> 
          <Route path="/marketing-services" element={<MarketingServices/>} />
        <Route path="/web-development" element={<WebDevelopment/>} />
        <Route path="/production-support" element={<ProductionSupport/>} />
        <Route path="/data-encryption-services" element={<DataEncryptionServices/>} />
        <Route path="/cyber-threat-monitoring" element={<CyberThreatMonitoring/>} />
{/* blogs */}
<Route path="/blog" element={<Blog />} />

        <Route path="/cybers" element={<Cyber />} />
        <Route path="/data" element={<DataPrivacy />} />
        <Route path="/future" element={<Futuretrends />} />
        <Route path="/role" element={<Roleofencrypt />} />
        <Route path="/top" element={<Toptrends />} />
        <Route path="/phishing" element={<PhishingScams />} />
        <Route path="/Databreach" element={<Databreach />} />
        <Route path="/student" element={<Studentform />} />
        <Route path="/CoursePaymentForm" element={<UIUX />} />
        <Route path="/Fullstack" element={<Fullstack />} />
        <Route path="/Cybersecurity" element={<Cybersecurity />} />
        <Route path="/Capturetheflag" element={<Capturetheflag />} />
        <Route path="/Hivestudentform" element={<Hivestudentform />} />
        <Route path="/GetHivestudent" element={<Hivestudent />} />
        <Route path="/Whatsapp" element={<Whatsapp />} />
        <Route path="/CertificatePage" element={<CertificatePage />} />
        <Route path="/certificate/:studentid" element ={<Certificate/>} />
        <Route path="/getRegistered" element={<GetRegistered />} />
        <Route path="/getUIUX" element={<GetUIUX />} />
        <Route path="/Ctf" element={<Ctf />} />
        <Route path="/Feedback" element={<Feedback />} />
        <Route path="/GetFeedback" element={<GetFeedback />} />
        <Route path="/PerformRatingform" element={<Employeeform />} />
        <Route path="/GetPerformRatingform" element={<Getperfomance />} />
        <Route path="/HiveStudentDetails" element={<Hivestudentdetails />} />





<Route path="/Interncertifi" element={<Interncertifi />} />
<Route path="/Fullstackintern" element={<Fullstackintern />} />
<Route path="/SecurityAnalystIntern" element={<Securityanalyintern />} />


        
        
        </Routes>
        <InternList />
        <BlogList />
     <ScrollButton /> 
    </>
  );
};

export default App;