import React from 'react';
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Paper,
  Grid,
  Button,
} from '@mui/material';

import logo from './ES.png';
import Signature from '../../../assets/Invoicelogo/SIGNATURE.png';
import QR from '../../../assets/Invoicelogo/Payuingupi.jpg';

// Define the InvoiceDetail component
const InvoiceDetail = ({ invoiceId }) => {
  // Retrieve all invoices from localStorage
  const storedInvoices = JSON.parse(localStorage.getItem('invoices') || '[]');

  // Find the specific invoice based on the provided invoiceId
  const invoice = storedInvoices.find((inv) => inv.id === invoiceId);

  if (!invoice) {
    return <Typography variant="h6">Invoice not found!</Typography>;
  }

  // Determine if the invoice is for service or product based on the invoice ID
  const isServiceInvoice = invoice.id.includes('SER');

  // Calculate the totals for unit prices and net amounts
  const totalUnitPrice = invoice.products.reduce((sum, product) => {
    const totalPrice = product.unitPrice * product.quantity;
    return sum + totalPrice;
  }, 0);

  const totalNetAmount = invoice.products.reduce((sum, product) => {
    const totalPrice = product.unitPrice * product.quantity;
    const discountAmount = (product.discount / 100) * totalPrice;
    const netAmount = totalPrice - discountAmount;
    return sum + netAmount;
  }, 0);

  return (
    <Box sx={{ padding: 2, maxWidth: '750px', margin: '0 auto' }}>
      {/* Print Button */}
      <Button 
        variant="contained" 
        color="primary" 
        onClick={() => window.print()} 
        sx={{ 
          marginBottom: 2, 
          '@media print': { display: 'none' } // Hide button during print
        }} 
      >
        Print Invoice
      </Button>

      {/* Top Section: Logo and Invoice Date/Number */}
      <Grid container justifyContent="space-between" sx={{ marginBottom: '20px' }}>
        <Grid item>
          {/* Logo */}
          <img src={logo} alt="ES Ethicsecur Softec" width="150px" />
        </Grid>
        <Grid item>
          {/* Invoice Date and Number */}
          <Typography variant="body2" sx={{ fontSize: '0.7rem' }}>
            Date: {invoice.date}
          </Typography>
          <Typography variant="body2" sx={{ fontSize: '0.7rem' }}>
            Due Date: 2/04/2025
          </Typography>
          <Typography variant="body2" sx={{ fontSize: '0.7rem' }}>
            Invoice Number: {invoice.id}
          </Typography>
        </Grid>
      </Grid>

      <Box sx={{ marginTop: '10px' }}>  
        {/* Middle Upper Section: Company and Customer Details */}
        <Grid container justifyContent="space-between">
          {/* Company Details */}
          <Grid item xs={6} sx={{ backgroundColor: '#e0f7fa', padding: '10px', borderRadius: '4px', 
            '-webkit-print-color-adjust': 'exact', // Ensure color on print for WebKit browsers
            'print-color-adjust': 'exact', // General support for color on print
          }}>
            <Typography variant="h6" sx={{ fontSize: '0.7rem', fontWeight: 'bold' }}>
              Service Provider:
            </Typography>
            <Typography variant="body2" sx={{ fontSize: '0.7rem' }}>
              CIN: U6299TZ2023PTC029658
            </Typography>
            <Typography variant="body2" sx={{ fontSize: '0.7rem' }}>
              Name of the Company: ES EthicSecur SofTec Pvt. Ltd.
            </Typography>
            <Typography variant="body2" sx={{ fontSize: '0.7rem' }}>
              Phone Number: 7550028487
            </Typography>
            <Typography variant="body2" sx={{ fontSize: '0.7rem' }}>
              O/A: ES EthicSecur SofTec Pvt. Ltd.
            </Typography>
            <Typography variant="body2" sx={{ fontSize: '0.7rem' }}>
              23-6, Abirami Garden, MGR Nagar, Nethimedu, Salem-636002
            </Typography>
          </Grid>

          {/* Customer Details */}
          <Grid item xs={5} sx={{ backgroundColor: '#e0f7fa', padding: '10px', borderRadius: '4px', 
            '-webkit-print-color-adjust': 'exact', // Ensure color on print for WebKit browsers
            'print-color-adjust': 'exact', // General support for color on print
          }}>
            <Typography variant="h6" sx={{ fontSize: '0.7rem', fontWeight: 'bold' }}>
              Customer Details:
            </Typography>
            {invoice.client && (
              <Typography variant="body2" sx={{ fontSize: '0.7rem' }}>
                Name of the Company: {invoice.client}
              </Typography>
            )}
            {invoice.phoneNumber && (
              <Typography variant="body2" sx={{ fontSize: '0.7rem' }}>
                Phone Number: {invoice.phoneNumber}
              </Typography>
            )}
            {invoice.gstNumber && (
              <Typography variant="body2" sx={{ fontSize: '0.7rem' }}>
                GST Number: {invoice.gstNumber}
              </Typography>
            )}
            {invoice.address && (
              <Typography variant="body2" sx={{ fontSize: '0.7rem' }}>
                Address: {invoice.address}
              </Typography>
            )}
          </Grid>
        </Grid>
      </Box>
      
      {/* Middle Section: Services Table */}
      <Box sx={{ marginTop: '20px' }}>
        <Typography variant="body1" sx={{ fontSize: '0.7rem' }}>Services Details</Typography>
        <TableContainer component={Paper} sx={{ boxShadow: 'none' }}>
          <Table>
            <TableHead>
              <TableRow>
                {/* Dynamically change the column header based on invoice type */}
                <TableCell sx={{ fontSize: '0.7rem' }}>
                  {isServiceInvoice ? 'Service Name' : 'Product Name'}
                </TableCell>
                <TableCell sx={{ fontSize: '0.7rem' }}>Quantity</TableCell>
                <TableCell sx={{ fontSize: '0.7rem' }}>Unit Price</TableCell>
                <TableCell sx={{ fontSize: '0.7rem' }}>Discount in %</TableCell>
                <TableCell sx={{ fontSize: '0.7rem' }}>Net Amount (Total price - discount)</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {invoice.products.map((product, index) => (
                <TableRow key={index}>
                  <TableCell sx={{ fontSize: '0.7rem' }}>{product.itemName}</TableCell>
                  <TableCell sx={{ fontSize: '0.7rem' }}>{product.quantity}</TableCell>
                  <TableCell sx={{ fontSize: '0.7rem' }}>{product.unitPrice}</TableCell>
                  <TableCell sx={{ fontSize: '0.7rem' }}>{product.discount}</TableCell>
                  <TableCell sx={{ fontSize: '0.7rem' }}>{product.netAmount}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>

        {/* Display Total Prices */}
        <Box sx={{ 
          display: 'flex', 
          flexDirection: 'column', 
          justifyContent: 'flex-start', 
          textAlign: 'right', 
          marginTop: 2 
        }}>
          <Typography 
            variant="body1" 
            sx={{ 
              fontSize: '0.7rem', 
              backgroundColor: '#e0f7fa', // light blue
              padding: '8px', 
              borderRadius: '4px', 
              fontWeight: 'bold',
              '-webkit-print-color-adjust': 'exact',
              'print-color-adjust': 'exact',
            }}
          >
            <strong>Total Amount:</strong> ₹{totalNetAmount.toFixed(2)}
          </Typography>
        </Box>
      </Box>

      {/* Middle Lower Section: Bank Details and Authorized Signature */}
      <Box sx={{ marginTop: '20px' }}>
  {/* Bank Details Section */}
  <Typography variant="body1" sx={{ fontSize: '0.7rem', marginBottom: 1 }}>Bank Details:</Typography>
  <Grid container spacing={2}>
    {/* Bank Details Column */}
    <Grid item xs={8} >
      <Typography variant="body2" sx={{ fontSize: '0.7rem' }}>Bank: IDFC First Bank</Typography>
      <Typography variant="body2" sx={{ fontSize: '0.7rem' }}>Account Name: ES ETHICSECUR SOFTEC PRIVATE LIMITED</Typography>
      <Typography variant="body2" sx={{ fontSize: '0.7rem' }}>Account Number: 10159483061</Typography>
      <Typography variant="body2" sx={{ fontSize: '0.7rem' }}>IFSC Code: IDFB0080591</Typography>
      <Typography variant="body2" sx={{ fontSize: '0.7rem' }}>SWIFT code: IDFBINBBMUM</Typography>
      <Typography variant="body2" sx={{ fontSize: '0.7rem' }}>Branch: Salem</Typography>
    </Grid>

    {/* QR Code Column */}
    <Grid item xs={4} sx={{ textAlign: 'right' , marginTop: -4 }}>
      <Typography variant="body2" sx={{ fontSize: '0.7rem', marginRight: 2 }}>Pay using UPI:</Typography>
      <img src={QR} alt="QR" width="120px" />
    </Grid>
  </Grid>

  {/* Authorized Signature */}
  <Box sx={{ textAlign: 'right', marginTop: 5, marginRight: 1 }}>
    <Typography variant="body2" sx={{ fontSize: '0.7rem' }}><img src={Signature} alt="Signature" width="100px" /></Typography>
  </Box>

  <Box sx={{ textAlign: 'right', marginTop: 0, marginRight: 1 }}>
    <Typography variant="body2" sx={{ fontSize: '0.7rem' }}>Authorized Signature</Typography>
  </Box>
</Box>

{/* Terms and Conditions Section */}
<Box sx={{ marginTop: '-90px'  }}>
  <Typography variant="body1" sx={{ fontSize: '0.7rem', marginBottom: 1 }}>Terms and Conditions:</Typography>
  <Grid container spacing={2}>
    <Grid item xs={12}>
      <Typography variant="body2" sx={{ fontSize: '0.7rem' }}>
       Payments are due within 7 days of the invoice date.
      </Typography>
      <Typography variant="body2" sx={{ fontSize: '0.7rem' }}>
         Late payments will incur a 5% late fee for every 15 days of delay.
      </Typography>
      <Typography variant="body2" sx={{ fontSize: '0.7rem' }}>
         For any inquiries or clarifications regarding this invoice,please contact
      </Typography>
      <Typography variant="body2" sx={{ fontSize: '0.7rem'}}>
         please contact finance department at finance@ethicsecur.com or 7550028487
      </Typography>
    </Grid>
  </Grid>
</Box>

      {/* Footer Section: Supplier Company Info */}
      <Box 
        sx={{ 
          textAlign: 'center', 
          marginTop: 4, 
          fontSize: '0.5rem',
          position: 'relative',
          paddingBottom: '20px'
        }}
      >
        <Typography variant="body2">Supplier Company: ES EthicSecur SofTec Pvt. Ltd.</Typography>
        <Typography variant="body2">Contact: 7550028487</Typography>
      </Box>
    </Box>
  );
};

export default InvoiceDetail;