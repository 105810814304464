import React, { useEffect, useState } from "react";
import axios from "axios";

const StudentList = () => {
  const [students, setStudents] = useState([]);
  const [selectedStudent, setSelectedStudent] = useState(null);
  const [scores, setScores] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);

  useEffect(() => {
    axios
      .get("https://qst1iuuu17.execute-api.ap-south-1.amazonaws.com/prod/login/All")
      .then((response) => {
        setStudents(response.data);
      })
      .catch((error) => {
        console.error("Error fetching students:", error);
      });
  }, []);

  const fetchScores = (studentid) => {
    axios
      .get(`https://qst1iuuu17.execute-api.ap-south-1.amazonaws.com/prod/login/student/${studentid}`)
      .then((response) => {
        setSelectedStudent(response.data.student_details);
        setScores(response.data.scores);
        setModalOpen(true); // Open modal
      })
      .catch((error) => {
        console.error("Error fetching scores:", error);
      });
  };

  return (
    <div style={{ textAlign: "center", padding: "20px", position: "relative" }}>
      <h1>Hive Student List</h1>

      <table border="1" style={{ width: "80%", margin: "auto", borderCollapse: "collapse" }}>
        <thead>
          <tr>
            <th>Student ID</th>
            <th>Name</th>
            <th>Email</th>
            <th>School Name</th>
            <th>Phone</th>
            <th>Group</th>
            <th>Exam Written on</th>
            <th>Exam Results</th>
          </tr>
        </thead>
        <tbody>
          {students.map((student) => (
            <tr key={student.studentid}>
              <td>{student.studentid}</td>
              <td>{student.name}</td>
              <td>{student.email}</td>
              <td>{student.schoolname}</td>
              <td>{student.phone}</td>
              <td>{student.group}</td>
              <td>{new Date(student.createdAt).toLocaleDateString()}</td>
              <td>
                <button onClick={() => fetchScores(student.studentid)}>View Results</button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {/* Blur Background & Modal */}
      {modalOpen && (
        <>
          {/* Background Blur */}
          <div
            style={{
              position: "fixed",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              backgroundColor: "rgba(0, 0, 0, 0.5)",
              backdropFilter: "blur(5px)",
              zIndex: 1,
            }}
            onClick={() => setModalOpen(false)} // Close on background click
          ></div>

          {/* Modal Box */}
          <div
            style={{
              position: "fixed",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              backgroundColor: "#fff",
              padding: "20px",
              borderRadius: "10px",
              boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
              zIndex: 2,
              width: "300px",
              textAlign: "center",
            }}
          >
            <h2>{selectedStudent?.name}'s Exam Scores</h2>
            <ul style={{ listStyle: "none", padding: 0 }}>
              {scores.length > 0 ? (
                scores.map((score) => (
                  <li key={score._id} style={{ marginBottom: "10px" }}>
                    {score.papername}: <strong>{score.score}</strong>
                  </li>
                ))
              ) : (
                <p>No Scores Available</p>
              )}
            </ul>
            <button onClick={() => setModalOpen(false)}>Close</button>
          </div>
        </>
      )}
    </div>
  );
};

export default StudentList;
