import React, { useState } from 'react';
import {
  Box,
  TextField,
  Button,
  Typography,
  Snackbar,
  Alert,
  Grid,
  CircularProgress,
  MenuItem,
} from '@mui/material';
import { styled } from '@mui/system';
import axios from 'axios';
import AnimatedBackground from './Animation';
import topImage from '../../assets/ESlogo.png';

const BackgroundContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  minHeight: '100vh',
  padding: theme.spacing(2),
  backgroundSize: 'cover',
  backgroundPosition: 'center',
}));

const FormContainer = styled(Box)(({ theme }) => ({
  width: '60%',
  maxWidth: 400,
  margin: '0 auto',
  padding: theme.spacing(5),
  border: '1px solid #ccc',
  borderRadius: 1,
  backgroundColor: '#fff',
  boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(2),
    width: '90%',
  },
}));

const PayButton = styled(Button)(({ theme }) => ({
  width: '100%',
  padding: theme.spacing(1),
  backgroundColor: '#007bff',
  color: '#fff',
  fontSize: 16,
  '&:hover': {
    backgroundColor: '#0056b3',
  },
}));

const TopImage = styled('img')({
  display: 'block',
  width: '100%',
  maxHeight: 150,
  objectFit: 'cover',
  marginBottom: 20,
  borderRadius: 8,
});

const StudentForm = () => {
  const [formData, setFormData] = useState({
    studentId: '1',
    name: '',
    email: '',
    mobilenumber: '',
    candidatetype: '',
  });

  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handlePayment = async () => {
    if (!window.Razorpay) {
      alert('Razorpay SDK failed to load. Are you online?');
      return;
    }

    const options = {
      key: 'rzp_live_xAPYPcMAEMICyU',
      amount: 9999 * 100,
      currency: 'INR',
      name: 'ES Ethicsecur',
      description: 'Razorpay Online Payment',
      handler: async (response) => {
        if (response.razorpay_payment_id) {
          await handleRazorpaySuccess(response.razorpay_payment_id);
        } else {
          setError('Payment ID missing');
        }
      },
      prefill: {
        name: formData.name || 'ES Ethicsecur',
        email: formData.email || 'test@test.com',
        contact: formData.mobilenumber || '9876543210',
      },
      notes: {
        address: 'Razorpay corporate office',
      },
      theme: {
        color: '#00b0ff',
      },
      
    };

    const pay = new window.Razorpay(options);
    pay.open();
  };

  const handleRazorpaySuccess = async (paymentId) => {
    try {
      setLoading(true);
      await axios.post('https://qcyokzjqdb.execute-api.ap-south-1.amazonaws.com/prod/api/student/addSecurityAnalystIntern', {  
        ...formData,
        paymentId,
      });
      setSuccess(true);
      setFormData({
        studentId: (parseInt(formData.studentId) + 1).toString(),
        name: '',
        email: '',
        mobilenumber: '',
        candidatetype: '',
      });
    } catch (err) {
      setError(err.response?.data?.message || 'An error occurred');
    } finally {
      setLoading(false);
    }
  };

  const handleClose = () => {
    setSuccess(false);
    setError(null);
  };

  return (
    <BackgroundContainer>
      <AnimatedBackground />
      <FormContainer>
        <TopImage src={topImage} alt="Top Image" />
        <Typography variant="h6" align="center" gutterBottom>
        Security Analyst Intern
        </Typography>
        <Grid container spacing={2}>
          {[ 
            { label: 'Name', name: 'name' },
            { label: 'Email', name: 'email', type: 'email' },
            { 
              label: 'Mobile Number', 
              name: 'mobilenumber', 
              type: 'text', 
              inputProps: { pattern: '^[0-9]{10}$' }, 
              placeholder: '10-digit mobile number' 
            },
          ].map((field) => (
            <Grid item xs={12} sm={12} key={field.name}>
              <TextField
                label={field.label}
                name={field.name}
                value={formData[field.name]}
                onChange={handleChange}
                fullWidth
                required
                type={field.type || 'text'}
                {...field.inputProps && { inputProps: field.inputProps }}
                {...field.placeholder && { placeholder: field.placeholder }}
              />
            </Grid>
          ))}
          <Grid item xs={12}>
            <TextField
              select
              label="Candidate Type"
              name="candidatetype"
              value={formData.candidatetype}
              onChange={handleChange}
              fullWidth
              required
            >
              <MenuItem value="Student">Student</MenuItem>
              <MenuItem value="Working Professional">Working Professional</MenuItem>
            </TextField>
          </Grid>
          <Grid item xs={12}>
            <PayButton onClick={handlePayment} disabled={loading}>
              {loading ? <CircularProgress size={24} color="inherit" /> : 'Pay Now'}
            </PayButton>
          </Grid>
        </Grid>
        <Snackbar open={success} autoHideDuration={6000} onClose={handleClose}>
          <Alert onClose={handleClose} severity="success">
            Payment successful and details stored!
          </Alert>
        </Snackbar>
        <Snackbar open={!!error} autoHideDuration={6000} onClose={handleClose}>
          <Alert onClose={handleClose} severity="error">
            {error}
          </Alert>
        </Snackbar>
      </FormContainer>
    </BackgroundContainer>
  );
};

export default StudentForm;