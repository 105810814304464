import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  CircularProgress,
  Snackbar,
  Alert,
} from '@mui/material';
import axios from 'axios';

const FeedbackList = () => {
  const [feedbackList, setFeedbackList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Fetch feedback data from the API
  useEffect(() => {
    const fetchFeedback = async () => {
      try {
        const response = await axios.get('https://qcyokzjqdb.execute-api.ap-south-1.amazonaws.com/prod/api/student/getAllFeedback');
        setFeedbackList(response.data); // Assuming the API returns an array of feedback objects
        setLoading(false);
      } catch (err) {
        setError(err.message || 'An error occurred while fetching data');
        setLoading(false);
      }
    };

    fetchFeedback();
  }, []);

  const handleClose = () => {
    setError(null);
  };

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="100vh">
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Snackbar open={!!error} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="error">
          {error}
        </Alert>
      </Snackbar>
    );
  }

  return (
    <Box padding={4}>
      <Typography variant="h4" align="center" gutterBottom>
        Student Feedback List
      </Typography>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Student ID</TableCell>
              <TableCell>Name</TableCell>
              <TableCell>Handles Disagreements Professionally</TableCell>
              <TableCell>Recognition Frequency</TableCell>
              <TableCell>Constructive Feedback</TableCell>
              <TableCell>Abuse Faced</TableCell>
              <TableCell>Change in HR/Managers</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {feedbackList.map((feedback) => (
              <TableRow key={feedback.studentid}>
                <TableCell>{feedback.studentid}</TableCell>
                <TableCell>{feedback.name}</TableCell>
                <TableCell>{feedback.DoManagershandledisagreementsproffessinally}</TableCell>
                <TableCell>{feedback.HowFrequentlyDoYouReceiveRecognitionFromYourManagers}</TableCell>
                <TableCell>{feedback.DoYouReqularlyReceiveConstructivePerformanceFeedbackFromYourManagersofHR}</TableCell>
                <TableCell>{feedback.AnyMentalPhysicalAbuseYouFacedWithinOurOrganisation}</TableCell>
                <TableCell>{feedback.DoYouneedanyChangeinHRManagersSourceyesorno}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default FeedbackList;