import React, { useState, useEffect } from "react";
import axios from "axios"; // Make sure to install axios
import styled from "styled-components";
import {
  Typography,
  Card,
  Box,
  Grid,
  Button,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Rating,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import cybertrends from "../../assets/img/blogs_image/cyber_security_trends.jpg";
import Dataprivacy from "../../assets/img/blogs_image/dataprivacy.jpg";
import Futuretrends from "../../assets/img/blogs_image/future_cyber.jpg";
import TopNavbar from "../pages/Navbar";
import Footer from "../pages/Footer2";

import BackgroundImage from "../../assets/img/add/new_bg_ai.png";

export default function Interncertifi() {
  const [openReviewDialog, setOpenReviewDialog] = useState(false);
  const [rating, setRating] = useState(0);
  const [feedback, setFeedback] = useState("");
  const [selectedCourse, setSelectedCourse] = useState('');
  const [name, setName] = useState("");
  const [reviews, setReviews] = useState([]);
  const navigate = useNavigate();
  const [reviewedIds, setReviewedIds] = useState([]); // Track reviewed IDs
  const [id, setid] = useState("");

  const handleGetCertificate = () => {
    // Check if certificate ID is entered
    if (!id.trim()) {
      alert("Please enter a valid certificate ID.");
      return;
    }
  
    // Check if the student has already submitted a review
    if (reviewedIds.includes(id)) {
      navigate(`/certificate/${id}`); // Redirect to certificate page
      return;
    }
  
    // Open the review dialog if the student has not submitted a review
    setOpenReviewDialog(true);
  };
  
  const handleReviewSubmit = async () => {

    const reviewData = {
      studentid: id,
      name: name,
      courseName: selectedCourse,
      rating: rating,
      review: feedback,
    };

    try {
      const response = await axios.post("https://qcyokzjqdb.execute-api.ap-south-1.amazonaws.com/prod/api/student/submitReview", reviewData);

      if (response.status === 200 || response.status === 201) {
        alert("Thank you for your feedback!");
        setReviewedIds((prevIds) => [...prevIds, id]);
        navigate(`/certificate/${id}`);
      }
      else {
        console.log("Unexpected response status:", response.status);
        alert("Failed to submit your review. Please try again.");
      }
    } catch (error) {
      console.error("Error submitting review:", error);
      alert("Error submitting review. Please check your connection and try again.");
    } finally {
      setOpenReviewDialog(false);
    }
  };


  const handleDialogClose = () => {
    setOpenReviewDialog(false);
  };

  const blogBoxData = [
    {
      title: "Fullstack Intern",
      text: "This internship is helping us build a strong foundation in full-stack development.",
      tag: "company",
      link: "/Fullstackintern",
      img: cybertrends,
    },
    {
      title: "Data Privacy",
      text: "The importance of data privacy in the digital age.",
      tag: "company",
      author: "Deepadharsan, 5 days ago",
      link: "/data",
      img: Dataprivacy,
    },
    {
      title: "Future Trends",
      text: "Exploring future trends in technology.",
      tag: "company",
      author: "Akash, 6 days ago",
      link: "/future",
      img: Futuretrends,
    },
  ];

  const handleBoxClick = (link) => {
    navigate(link);
  };

  // Fetch reviews
  useEffect(() => {
    const fetchReviews = async () => {
      try {
        const response = await axios.get(
          "https://qcyokzjqdb.execute-api.ap-south-1.amazonaws.com/prod/api/student/getReviews"
        );
        setReviews(response.data);

        // Optionally preload reviewed IDs based on fetched data
        const reviewedIdsFromServer = response.data.map((review) => review.studentid);
        setReviewedIds(reviewedIdsFromServer);
      } catch (error) {
        console.error("Error fetching reviews:", error);
      }
    };

    fetchReviews();
  }, []);

  // const handleCertificateDownload = async () => {
  //   if (!id.trim()) {
  //     alert("Please enter a valid certificate ID");
  //     return;
  //   }

  //   try {
  //     const response = await fetch(
  //       https://qcyokzjqdb.execute-api.ap-south-1.amazonaws.com/prod/api/student/getInterncertificateByStudentid/${id}
  //     );
  //     const data = await response.json();

  //     if (response.ok) {
  //       navigate(/Cyberintern/${id}, {
  //         state: {
  //           name: data.name,
  //           courseName: data.courseName,
  //           startDate: new Date(data.createdAt).toLocaleDateString(),
  //           endDate: new Date(data.updatedAt).toLocaleDateString(),
  //         },
  //       });
  //     } else {
  //       alert(data.message || "Certificate not found.");
  //     }
  //   } catch (error) {
  //     console.error("Error fetching certificate:", error);
  //     alert("An error occurred. Please try again later.");
  //   }
  // };

  return (
    <>
      <TopNavbar />
      <Wrapper id="blog">
        <Container>
          <HeaderInfo>
            <h1 className="font40 extraBold">Our Interns</h1>
          </HeaderInfo>
          <CertificateSection>
            <h3>Hey! Here you can download your certificate</h3>
            <p>Enter your certificate ID and click submit:</p>
            <input
              type="text"
              placeholder="Enter Certificate ID"
              value={id}
              onChange={(e) => setid(e.target.value)}
            />
            <button onClick={handleGetCertificate}>Submit</button>
          </CertificateSection>
          <BlogGrid>
            {blogBoxData.map((blog, index) => (
              <BlogCard key={index} onClick={() => handleBoxClick(blog.link)}>
                <img src={blog.img} alt={blog.title} />
                <div className="card-content">
                  <h3>{blog.title}</h3>
                  <p>{blog.text}</p>
                  <p className="author">{blog.author}</p>
                </div>
              </BlogCard>
            ))}
          </BlogGrid>
        </Container>
        {/* Reviews Section */}
      <Box sx={{ mt: 10, px: 7 }}>
      <HeaderInfo>
            <h1 className="font40 extraBold">Student Reviews</h1>
          </HeaderInfo>
        <Grid container spacing={4}>
          {reviews.map((review, index) => (
            <Grid item xs={12} sm={6} md={4} key={index}>
              <Card
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                  p: 3,
                  boxShadow: 3,
                  borderRadius: 3,
                  borderLeft: "5px solid #007bff", // Yellow accent
                  transition: "box-shadow 0.3s, transform 0.3s", // Smooth hover effect
                  "&:hover": {
                    boxShadow: 6,
                    transform: "translateY(-5px)", // Lift effect on hover
                  },
                  backgroundColor: "#f9f9f9", // Light background for cards
                }}
              >
                <Box sx={{ flexGrow: 1 }}>
                  <Typography
                    variant="h6"
                    sx={{
                      fontWeight: "bold",
                      color: "#333",
                      marginBottom: 1,
                      fontSize: "1.1rem",
                    }}
                  >
                    {review.name}
                  </Typography>
                  <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
                    <Typography
                      variant="subtitle1"
                      sx={{
                        fontWeight: "bold",
                        color: "#666",
                        marginRight: 1,
                      }}
                    >
                      {review.courseName}
                    </Typography>
                    <Rating
                      value={review.rating}
                      precision={0.5}
                      readOnly
                      sx={{
                        color: "#FFD700", // Yellow stars
                        fontSize: "1.3rem",
                      }}
                    />
                  </Box>
                  <Typography
                    variant="body2"
                    sx={{
                      color: "#555",
                      fontStyle: "italic", // Italicized for feedback
                      lineHeight: 1.6,
                    }}
                  >
                    "{review.review}"
                  </Typography>
                </Box>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Box>
      </Wrapper>
      {/* Review Dialog */}
      <Dialog
        open={openReviewDialog}
        onClose={handleDialogClose}
        PaperProps={{
          sx: {
            borderRadius: 4, // Rounded corners for the dialog
            padding: 3, // Add internal padding for better spacing
            width: "650px", // Increase the dialog width
            maxWidth: "90%", // Ensure responsiveness
            margin: "auto", // Center the dialog
          },
        }}
        BackdropProps={{
          sx: {
            backgroundColor: "rgba(0, 0, 0, 0.4)", // Slightly darkened backdrop
            backdropFilter: "blur(8px)", // Blur effect
          },
        }}
      >
        <DialogTitle
          sx={{
            textAlign: "center",
            fontWeight: "bold",
            fontSize: "1.5rem",
          }}
        >
          Share Your Feedback
        </DialogTitle>
        <DialogContent>
          <Typography
            variant="body1"
            gutterBottom
            sx={{ textAlign: "center", color: "text.secondary" }}
          >
            How was your experience with the course?
          </Typography>
          {/* Name input field */}
          <TextField
            label="Your Name"
            variant="outlined"
            fullWidth
            sx={{ mb: 3, mt: 2 }}
            value={name}
            onChange={(event) => setName(event.target.value)}
          />
          {/* Dropdown to select the course */}
          <FormControl fullWidth sx={{ mb: 3 }}>
            <InputLabel id="course-select-label">Select Course</InputLabel>
            <Select
              labelId="course-select-label"
              value={selectedCourse}
              onChange={(event) => setSelectedCourse(event.target.value)}
              label="Select Course"
            >
              <MenuItem value="fullstack">Fullstack Development</MenuItem>
              <MenuItem value="uiux">UI/UX Development</MenuItem>
              <MenuItem value="flutter">Flutter Development</MenuItem>
              <MenuItem value="bda">BDA</MenuItem>
              <MenuItem value="dm">Digital Marketing</MenuItem>
              <MenuItem value="cyberSecurity">Cyber Security</MenuItem>
            </Select>
          </FormControl>
          {/* Rating input */}
          <Rating
            value={rating}
            precision={0.5} // Allows 0.5 increments
            onChange={(event, newValue) => setRating(newValue)}
            size="large"
            sx={{
              mb: 3,
              display: "flex",
              justifyContent: "center",
            }}
          />
          {/* Feedback input */}
          <TextField
            label="Write your feedback"
            variant="outlined"
            multiline
            rows={4}
            fullWidth
            sx={{
              mb: 2,
              "& .MuiOutlinedInput-root": {
                borderRadius: 3, // Rounded corners for text area
              },
            }}
            value={feedback}
            onChange={(event) => setFeedback(event.target.value)}
          />
        </DialogContent>
        <DialogActions sx={{ justifyContent: "center", gap: 2 }}>
          <Button
            onClick={handleDialogClose}
            variant="outlined"
            color="secondary"
            sx={{
              px: 4,
              borderRadius: 3,
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={handleReviewSubmit}
            variant="contained"
            color="primary"
            sx={{
              px: 4,
              borderRadius: 3,
            }}
          >
            Submit
          </Button>
        </DialogActions>
      </Dialog>
      <Footer />
    </>
  );
}

const Wrapper = styled.section`
  width: 100%;
  padding-top: 20px;
  color: #000;
  margin-top: 55px;
  background-image: url(${BackgroundImage});
  background-size: cover;
  background-position: center;
  background-attachment: fixed;

  @media (max-width: 768px) {
    background-attachment: scroll;
  }
`;

const Container = styled.div`
  margin-top: 50%;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 15px;
`;

const HeaderInfo = styled.div`
  margin-bottom: 30px;

  h1 {
    color: #fff;
    font-family: Cake sans;
    line-height: 1.5;
  }

  p {
    font-size: 18px;
    font-family: Cake sans;
    line-height: 1.5;
    color: #000;
  }
`;

const CertificateSection = styled.div`
  margin-bottom: 30px;
  text-align: center;

  h3 {
    font-size: 1.5em;
    font-family: Cake sans;
    color: #fff;
  }

  p {
    font-size: 1em;
    font-family: Cake sans;
    color: #fff;
  }

  input {
    padding: 10px;
    margin: 10px 0;
    width: 300px;
    font-size: 1em;
    border: 2px solid #ccc;
    border-radius: 5px;
  }

  button {
    padding: 10px 20px;
    font-size: 1em;
    color: #fff;
    background-color: #007bff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;

    &:hover {
      background-color: #0056b3;
    }
  }
`;

const BlogGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  gap: 20px;
  margin-top: 20px;

  @media (max-width: 768px) {
    gap: 15px;
  }

  @media (max-width: 480px) {
    gap: 10px;
  }
`;

const BlogCard = styled.div`
  width: 100%;
  max-width: 300px;
  background: #fff;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  transition: transform 0.3s ease-out;

  img {
    width: 100%;
    height: 180px;
    object-fit: contain;
  }

  .card-content {
    padding: 15px;
    text-align: center;

    h3 {
      margin: 10px 0;
      font-size: 1.2em;
      font-family: Cake sans;
    }

    p {
      margin: 5px 0;
      font-size: 0.9em;
      font-family: Cake sans;
    }

    .author {
      color: #888;
      font-size: 0.8em;
      font-family: Cake sans;
    }
  }

  &:hover {
    transform: scale(1.02);
  }
`;
