import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  TextField,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  Typography,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
} from '@mui/material';
import { FiPlusCircle } from 'react-icons/fi';
import ProductModal from './popup/ProductModel.jsx';
import { useNavigate } from 'react-router-dom';

const CreateInvoice = () => {
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [products, setProducts] = useState([]);
  const [customerName, setCustomerName] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [invoiceType, setInvoiceType] = useState('service');
  const [invoiceId, setInvoiceId] = useState('');
  const [gstNumber, setGstNumber] = useState('');
  const [address, setAddress] = useState('');
  const [invoiceDate, setInvoiceDate] = useState(new Date().toLocaleDateString());

  const currentMonth = new Date().getMonth() + 1;
  const currentYear = new Date().getFullYear();

  const generateInvoiceId = (type, counter) => {
    const prefix = 'IND';
    const typeCode = type === 'service' ? 'SER' : 'DEV';
    const monthCode = currentMonth.toString().padStart(2, '0');
    const counterCode = String(counter).padStart(3, '0');
    return `${prefix}-${typeCode}-${monthCode}${counterCode}`;
  };

  const getInvoiceCounter = () => {
    const storedInvoices = JSON.parse(localStorage.getItem('invoices') || '[]');
    const monthlyInvoices = storedInvoices.filter((invoice) => {
      return invoice.id.includes(invoiceType === 'service' ? 'SER' : 'DEV');
    });
    const lastInvoice = monthlyInvoices.length > 0 ? monthlyInvoices[monthlyInvoices.length - 1].id : null;
    if (lastInvoice) {
      const lastCounter = parseInt(lastInvoice.split('-')[2].slice(2)) || 0;
      return lastCounter + 1;
    }
    return 1;
  };

  useEffect(() => {
    const newCounter = getInvoiceCounter();
    const generatedId = generateInvoiceId(invoiceType, newCounter);
    setInvoiceId(generatedId);
  }, [invoiceType]);

  const handleCreateInvoice = () => {
    const totalPrice = products.reduce((total, product) => total + product.netAmount, 0);
    const newInvoiceData = {
      id: invoiceId,
      client: customerName,
      phoneNumber,
      gstNumber,
      address,
      date: invoiceDate,
      price: totalPrice,
      products,
    };
    const storedInvoices = JSON.parse(localStorage.getItem('invoices') || '[]');
    localStorage.setItem('invoices', JSON.stringify([...storedInvoices, newInvoiceData]));
    alert('Invoice Created and Saved!');
    navigate('/invoices-table');
  };

  return (
    <Box sx={{ padding: 2 }}>
      <TextField label="Invoice ID" fullWidth value={invoiceId} disabled sx={{ marginBottom: 2 }} />
      <TextField label="Customer Name" fullWidth value={customerName} onChange={(e) => setCustomerName(e.target.value)} sx={{ marginBottom: 2 }} />
      <TextField label="Phone Number" fullWidth value={phoneNumber} onChange={(e) => setPhoneNumber(e.target.value)} sx={{ marginBottom: 2 }} />
      <TextField label="GST Number" fullWidth value={gstNumber} onChange={(e) => setGstNumber(e.target.value)} sx={{ marginBottom: 2 }} />
      <TextField label="Customer Address" fullWidth value={address} onChange={(e) => setAddress(e.target.value)} sx={{ marginBottom: 2 }} />
      <TextField label="Invoice Date" fullWidth value={invoiceDate} onChange={(e) => setInvoiceDate(e.target.value)} sx={{ marginBottom: 2 }} />
      <FormControl fullWidth sx={{ marginBottom: 2 }}>
        <InputLabel>Invoice Type</InputLabel>
        <Select value={invoiceType} onChange={(e) => setInvoiceType(e.target.value)}>
          <MenuItem value="service">Service</MenuItem>
          <MenuItem value="product">Product</MenuItem>
        </Select>
      </FormControl>
      <Button variant="contained" startIcon={<FiPlusCircle />} onClick={() => setIsModalOpen(true)}>Add Product</Button>
      <ProductModal open={isModalOpen} onClose={() => setIsModalOpen(false)} onAddProduct={(product) => setProducts([...products, product])} />
      <TableContainer component={Paper} sx={{ marginTop: 2 }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Item Name</TableCell>
              <TableCell>Quantity</TableCell>
              <TableCell>Unit Price</TableCell>
              <TableCell>Discount %</TableCell>
              <TableCell>Net Amount</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {products.map((product, index) => (
              <TableRow key={index}>
                <TableCell>{product.itemName}</TableCell>
                <TableCell>{product.quantity}</TableCell>
                <TableCell>{product.unitPrice}</TableCell>
                <TableCell>{product.discount}</TableCell>
                <TableCell>{product.netAmount}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Typography variant="h6" sx={{ marginTop: 2 }}>
        Total Amount: {products.reduce((total, product) => total + product.netAmount, 0)}
      </Typography>
      <Button variant="contained" onClick={handleCreateInvoice} sx={{ marginTop: 2 }}>
        Create Invoice
      </Button>
    </Box>
  );
};

export default CreateInvoice;
