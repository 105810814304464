import React, { useEffect, useState } from "react";
import styled from "styled-components";
import axios from "axios";

const FeedbackContainer = styled.div`
  max-width: 1200px;
  margin: 20px auto;
  padding: 20px;
  background: #f9f9f9;
  border-radius: 10px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
`;

const FeedbackCard = styled.div`
  background: #ffffff;
  padding: 20px;
  margin-bottom: 20px;
  border-radius: 8px;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
`;

const EmployeeName = styled.h2`
  margin-bottom: 5px;
  color: #333;
`;

const Role = styled.p`
  font-size: 16px;
  font-weight: bold;
  color: #555;
  margin-bottom: 10px;
`;

const Detail = styled.p`
  font-size: 14px;
  color: #666;
  margin-bottom: 5px;
`;

const SkillList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 10px 0;
`;

const SkillItem = styled.li`
  background: #eef2ff;
  display: inline-block;
  padding: 8px 12px;
  border-radius: 5px;
  margin: 5px;
  font-size: 14px;
  color: #333;
`;

const FeedbackPage = () => {
  const [feedbacks, setFeedbacks] = useState([]);

  useEffect(() => {
    const fetchFeedbacks = async () => {
      try {
        const response = await axios.get("https://qcyokzjqdb.execute-api.ap-south-1.amazonaws.com/prod/api/student/Getallempfeedbacks");
        setFeedbacks(response.data);
      } catch (error) {
        console.error("Error fetching feedback:", error);
      }
    };

    fetchFeedbacks();
  }, []);

  return (
    <FeedbackContainer>
      <h1>Employees Performance Rating Form details</h1>
      {feedbacks.map((feedback) => (
        <FeedbackCard key={feedback._id}>
          <EmployeeName>{feedback.name}</EmployeeName>
          <Role>Role: {feedback.role}</Role>
          <Detail><strong>Date of Joining:</strong> {new Date(feedback.dateOfJoining).toLocaleDateString()}</Detail>
          <Detail><strong>Define your work done this year (50 words):</strong> {feedback.workSummary}</Detail>
          <Detail><strong>Do you get any accolades/Rewards from HR/Manager:</strong> {feedback.accolades}</Detail>
          <Detail><strong>Explain your productive work towards organization:</strong> {feedback.productiveWork}</Detail>
          <Detail><strong>Mention the project/app website/usecases done with name/components:</strong> {feedback.projects}</Detail>
          <Detail><strong>Any volunteer/organization activities/knowledge sharing sessions (min 3 to 4):</strong> {feedback.activities}</Detail>
          <Detail><strong>Role Specific Answer:</strong> {feedback.roleSpecificAnswer}</Detail>
          
          <h3>What are skills you get experience/proficient after joined in this organization?</h3>
          <SkillList>
            {feedback.skills.map((skill) => (
              <SkillItem key={skill._id}>
                {skill.skill} (Rating: {skill.rating})
              </SkillItem>
            ))}
          </SkillList>
        </FeedbackCard>
      ))}
    </FeedbackContainer>
  );
};

export default FeedbackPage;
