import React, { useState } from "react";
import {
  Box,
  TextField,
  Button,
  Typography,
  Snackbar,
  Alert,
  Grid,
  CircularProgress,
  FormControl,
  FormLabel,
  Select,
  MenuItem,
  Rating,
} from "@mui/material";
import { styled } from "@mui/system";
import axios from "axios";
import AnimatedBackground from '../../studentPaymentForm/Animation';
import topImage from '../../../assets/ESlogo.png';

const BackgroundContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  minHeight: "100vh",
  padding: theme.spacing(2),
  backgroundSize: "cover",
  backgroundPosition: "center",
}));

const FormContainer = styled(Box)(({ theme }) => ({
  width: "60%",
  maxWidth: 700,
  margin: "0 auto",
  padding: theme.spacing(4),
  border: "1px solid #ccc",
  borderRadius: 1,
  backgroundColor: "#fff",
  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
  [theme.breakpoints.down("sm")]: {
    padding: theme.spacing(2),
    width: "90%",
  },
}));

const SubmitButton = styled(Button)(({ theme }) => ({
  width: "100%",
  padding: theme.spacing(1),
  backgroundColor: "#007bff",
  color: "#fff",
  fontSize: 16,
  "&:hover": {
    backgroundColor: "#0056b3",
  },
}));

const TopImage = styled("img")({
  display: "block",
  width: "100%",
  maxHeight: 150,
  objectFit: "cover",
  marginBottom: 20,
  borderRadius: 8,
});

const EmployeeFeedbackForm = () => {
  const [formData, setFormData] = useState({
    Studentid: "1",
    name: "",
    dateOfJoining: "",
    role: "",
    workSummary: "",
    skills: [],
    accolades: "",
    productiveWork: "",
    projects: "",
    activities: "",
    roleSpecificAnswer: "",
  });

  const [skillInput, setSkillInput] = useState("");
  const [skillRating, setSkillRating] = useState(2);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(null);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleAddSkill = () => {
    if (skillInput) {
      setFormData((prevData) => ({
        ...prevData,
        skills: [...prevData.skills, { skill: skillInput, rating: skillRating }],
      }));
      setSkillInput("");
      setSkillRating(2);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      await axios.post("https://qcyokzjqdb.execute-api.ap-south-1.amazonaws.com/prod/api/student/AddEmp", formData);
      setSuccess(true);
      setFormData({
        Studentid: "1",
        name: "",
        dateOfJoining: "",
        role: "",
        workSummary: "",
        skills: [],
        accolades: "",
        productiveWork: "",
        projects: "",
        activities: "",
        roleSpecificAnswer: "",
      });
    } catch (err) {
      setError(err.response?.data?.message || "An error occurred");
    } finally {
      setLoading(false);
    }
  };

  const handleCloseSnackbar = () => {
    setSuccess(false);
    setError(null);
  };

  return (
    <BackgroundContainer>
      <AnimatedBackground />
      <FormContainer>
        <TopImage src={topImage} alt="Top Image" />
        <Typography variant="h6" align="center" gutterBottom>
          Performance Rating Form 2025
        </Typography>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="body1">Employee Name</Typography>
              <TextField name="name" label="Name" fullWidth required onChange={handleChange} value={formData.name} disabled={loading} />
            </Grid>
            <Grid item xs={12}>
              <TextField name="dateOfJoining" label="Date of Joining" type="date" fullWidth required onChange={handleChange} value={formData.dateOfJoining} InputLabelProps={{ shrink: true }} disabled={loading} />
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth required>
                <FormLabel>Your Role</FormLabel>
                <Select name="role" value={formData.role} onChange={handleChange} disabled={loading}>
                  <MenuItem value="BDA">BDA</MenuItem>
                  <MenuItem value="FullStackDeveloper">Full Stack Developer</MenuItem>
                  <MenuItem value="DigitalMarketing">Digital Marketing</MenuItem>
                  <MenuItem value="CyberSecurity">CyberSecurity</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body1">Define your work done this year (50 words)</Typography>
              <TextField name="workSummary" fullWidth multiline rows={2} required onChange={handleChange} value={formData.workSummary} disabled={loading} />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body1">What are skills you get experience/proficient after joined in this organization? ⭐:beginner, ⭐⭐:intermediate, ⭐⭐⭐:advanced and ⭐⭐⭐⭐⭐:expert</Typography>
              <Grid container spacing={1}>
                <Grid item xs={6}>
                  <TextField label="Skill" value={skillInput} onChange={(e) => setSkillInput(e.target.value)} fullWidth disabled={loading} />
                </Grid>
                <Grid item xs={4}>
                  <Rating value={skillRating} onChange={(e, newValue) => setSkillRating(newValue)} disabled={loading} />
                </Grid>
                <Grid item xs={2}>
                  <Button onClick={handleAddSkill} disabled={loading}>Add</Button>
                </Grid>
              </Grid>
              {formData.skills.map((s, index) => (
                <Typography key={index}>{s.skill}: {"⭐".repeat(s.rating)}</Typography>
              ))}
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body1">Do you get any accolades/Rewards from HR/Manager?</Typography>
              <TextField name="accolades" fullWidth multiline rows={2} onChange={handleChange} value={formData.accolades} disabled={loading} />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body1">Explain your productive work towards organization</Typography>
              <TextField name="productiveWork" fullWidth multiline rows={2} onChange={handleChange} value={formData.productiveWork} disabled={loading} />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body1">Mention the project/app website/usecases done with name/components</Typography>
              <TextField name="projects" fullWidth multiline rows={2} onChange={handleChange} value={formData.projects} disabled={loading} />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body1">Any volunteer/organization activities/knowledge sharing sessions (min 3 to 4)?</Typography>
              <TextField name="activities" fullWidth multiline rows={2} onChange={handleChange} value={formData.activities} disabled={loading} />
            </Grid>
            {formData.role === "BDA" && (
              <Grid item xs={12}>
                <TextField name="roleSpecificAnswer" label="Mention your leads (conversion leads)" fullWidth multiline rows={2} onChange={handleChange} value={formData.roleSpecificAnswer} disabled={loading} />
              </Grid>
            )}
            {formData.role === "FullStackDeveloper" && (
              <Grid item xs={12}>
                <TextField name="roleSpecificAnswer" label="Mention your project/website/application" fullWidth multiline rows={2} onChange={handleChange} value={formData.roleSpecificAnswer} disabled={loading} />
              </Grid>
            )}
            {formData.role === "DigitalMarketing" && (
              <Grid item xs={12}>
                <TextField name="roleSpecificAnswer" label="Mention the leads team which you generated/tasks done" fullWidth multiline rows={2} onChange={handleChange} value={formData.roleSpecificAnswer} disabled={loading} />
              </Grid>
            )}
              {formData.role === "CyberSecurity" && (
              <Grid item xs={12}>
                <TextField name="roleSpecificAnswer" label="Any cybersecurity activities towards organization (real time )?" fullWidth multiline rows={2} onChange={handleChange} value={formData.roleSpecificAnswer} disabled={loading} />
              </Grid>
            )}
            <Grid item xs={12}>
              <SubmitButton type="submit" disabled={loading}>
                {loading ? <CircularProgress size={24} color="inherit" /> : "Submit Feedback"}
              </SubmitButton>
            </Grid>
          </Grid>
        </form>
      </FormContainer>
      <Snackbar open={success || !!error} autoHideDuration={6000} onClose={handleCloseSnackbar}>
        <Alert onClose={handleCloseSnackbar} severity={success ? "success" : "error"} sx={{ width: '100%' }}>
          {success ? "Feedback submitted successfully!" : error}
        </Alert>
      </Snackbar>
    </BackgroundContainer>
  );
};

export default EmployeeFeedbackForm;